import React from 'react';

import Layout from '../components/layout';

const IndexPage = () => {
    return (
        <Layout>
            <h1>This website is currently being rebuilt! Check back soon.</h1>
            <h2>New Stack:</h2>
            <ul>
                <li>Astro</li>
                <li>Bun</li>
                <li>Tailwind CSS</li>
                <li>Hosting -> Moving to Vercel</li>
            </ul>
            
        </Layout>
    );
};

export default IndexPage;
